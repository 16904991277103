import { graphql } from "gatsby"
import React from "react"
import Cadre from "../components/Cadre/Cadre"
import CategoryComponent from "../components/Menu/Categories/Category"
import Seo from "../components/Seo"
import { Carte, SeoContent } from "../types"

import "./menu.less"

interface QueryMenu {
  contentfulCarte: Carte
  contentfulSeo: SeoContent
}

interface MenuPageProps {
  data: QueryMenu
}

export default function MenuPage({ data }: MenuPageProps) {
  return (
    <div className="page-menu">
      <Seo
        data={{
          ...data.contentfulSeo,
          title: "Mobster Bar - Carte",
        }}
      />
      <Cadre
        color="#E6A133"
        widthX={4}
        widthY={4}
        ratioX={0.5}
        ratioY={0.5}
        paddingContent={0.5}
        widthLargeLine={2}
        widthLightLine={1}
        style={{ flex: 1 }}
        styleContent={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h1>Mobster Bar - Carte</h1>
        <div className="categories-container">
          <div className="burger-category">
            {/* <span className="definition-menu">MENU = BURGER + FRITES</span> */}
            <CategoryComponent category={data.contentfulCarte.categories[0]} />
          </div>
          <div className="other-categories">
            {data.contentfulCarte.categories.map(
              (category, index) =>
                index !== 0 && (
                  <CategoryComponent
                    key={`category-cmp-${index}`}
                    category={category}
                  />
                )
            )}
          </div>
        </div>
      </Cadre>
    </div>
  )
}

export const query = graphql`
  query MenuQuery {
    contentfulCarte {
      contentful_id
      categories {
        items {
          ... on ContentfulDessert {
            contentful_id
            internal {
              type
            }
            name
            subtitle
            price
            childContentfulDessertDescriptionTextNode {
              description
            }
          }
          ... on ContentfulDrinksCategory {
            contentful_id
            internal {
              type
            }
            price
            quantity
            titre
            drinks {
              contentful_id
              name {
                name
              }
              price
              description
            }
            description
          }
          ... on ContentfulMenuBurger {
            contentful_id
            internal {
              type
            }
            title
            asterisques
            burgers {
              contentful_id
              name
              subtitle
              description {
                description
              }
            }
          }
          ... on ContentfulSupplement {
            contentful_id
            internal {
              type
            }
            price
            title
            childContentfulSupplementDescriptionTextNode {
              description
            }
            supplements {
              contentful_id
              internal {
                type
              }
              childContentfulSupplementDescriptionTextNode {
                description
              }
              price
              title
            }
          }
        }
        name
      }
    }
    contentfulSeo {
      title
      childContentfulSeoDescriptionTextNode {
        description
      }
      childContentfulSeoKeywordsTextNode {
        keywords
      }
      ogtitle
      ogsiteName
      ogdescription {
        ogdescription
      }
      ogimage {
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
        description
      }
      twittercard
      twittertitle
      childContentfulSeoTwitterdescriptionTextNode {
        twitterdescription
      }
      twitterimage {
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
        description
      }
    }
  }
`
